<template>
	<div class="updatePassword">
		<!--        <div style=" width: 92%;height: 1.2rem;"></div>-->
		<rxNavBar title="修改报价"></rxNavBar>
<!--		<div class="img_group">-->
<!--			<img :src="headImg" class="oneImg">-->
<!--			<div class="setPsd">新增预约</div>-->
<!--		</div>-->

		<div class="appointmentDetail"  >
			<div class="roomPhoto">
				<img class="roomPhotoImage" :src="appointmentData.photo">
			</div>
			<div class="roomMesage">
				<div class="address">{{appointmentData.roomDetailedAddress + appointmentData.roomName}}</div>
				<div class="areaDiv">
					<div class="area">{{appointmentData.cityProperStr}}</div>
					<span class="devide">|</span>
					<div class="area">{{appointmentData.roomArea ? appointmentData.roomArea + '㎡' : '暂无'}}</div>
					<span class="devide">|</span>
					<div class="area">{{appointmentData.roomFloor + '层'}}</div>
				</div>
				<div class="money">{{appointmentData.roomRent}}<span class="spanMonth">/月</span></div>
			</div>

		</div>
    <div class="timeDiv">
      <div class="content-have"></div>
      <span class="part-inputpart-row-header">成本价</span>
      <span class="content-divide">|</span>
      <div class="nopadding">
        <van-field class="nopadding"  v-model="floorPrice" placeholder="底价" readonly />
      </div>
      <span class="part-inputpart-row-right part-inputpart-row-short-graytext">元</span>
    </div>
    <div class="timeDiv">
<!--      <span :class="!offerPrice ? 'content-none' : 'content-have' "></span>-->
      <div class="content-have"></div>
      <span class="part-inputpart-row-header">报价</span>
      <span class="content-divide">|</span>
      <div class="nopadding">
        <van-field class="nopadding" type="digit" v-model="offerPrice" placeholder="报价" />
      </div>
      <span class="part-inputpart-row-right part-inputpart-row-short-graytext">元</span>
    </div>
	<div class="timeDiv">
      <div class="content-have"></div>
      <span class="part-inputpart-row-header">自动恢复首次出房价</span>
      <span class="content-divide">|</span>
      <div class="nopadding">
		<van-radio-group class="radio_group" direction="horizontal" v-model="isChangePrice">
			<van-radio name="1" checked-color="#f86513" class="part-inputpart-row-header">是</van-radio>
			<van-radio name="0" checked-color="#f86513" class="part-inputpart-row-header">否</van-radio>
		</van-radio-group>
      </div>
    </div>

		<van-button class="saveButton_Enable" @click="updataHousePrice()">
			修改报价
		</van-button>

	</div>
</template>

<script>
import {
  NavBar,
  NumberKeyboard,
  Toast,
  Calendar,
  DatetimePicker,
  Button,
  Popup, Field,
  RadioGroup,Radio
} from 'vant'
	import {
		checkAndroid, checkIOS,
		globaluserId,
    getStaffId,
		money, openInWebview,
		responseUtil
	} from "../../libs/rongxunUtil";
import {
  bannerLinkUser,
  roomDetail,
  addUserAppointmentNew, updataHousePrice
} from "../../getData/getData";

	//调用android关闭页面方法*******begin*********
	function backToAndroid(){
		window.himi.closeWindow();
	}
	// import {} from "../../getData/getData";
	// import {responseUtil} from "../../libs/rongxunUtil";
	import rxNavBar from "../../components/rongxun/rx-navBar/navBar";
	export default {
		components: {
			[NavBar.name]: NavBar,
			[NumberKeyboard.name]: NumberKeyboard,
			[Toast.name]: Toast,
			[Button.name]: Button,
			[Calendar.name]: Calendar,
			[DatetimePicker.name]: DatetimePicker,
			[Popup.name]: Popup,
      [Field.name]:Field,
			rxNavBar,
			[RadioGroup.name]: RadioGroup,
            [Radio.name]: Radio,
		},
		data() {
			return {
        floorPrice: '',  // 底价
        offerPrice: '',  // 报价
		isChangePrice:'1',  //是否恢复
				roomId: '',
				month: "",
				date: new Date(),
				dateTime:true,
				show: false,
				showaa: false,
				dateformat: "",
				errorImg: 'https://himihome.oss-cn-qingdao.aliyuncs.com/images/platform/templatetmp/2589de77-4277-4be7-add9-f09f2b176e57.png',

				timeImg: require("../../assets/images/timepick.png"),
				headImg: require("../../assets/images/head-quotation-mark.png"),

				appointmentData: {
					photo: require("../../assets/images/1.png"),
					address: "天通苑南 天通苑西区 朝南主…",
					district: "沙河口",
					area: "123m²",
					floor: "6层",
					money: "¥ 809"
				},
				isChangePrice:'',
			}
		},
		mounted() {
			this.roomId = this.$route.query.room_id
			this.initData()
		},
		beforeRouteEnter(to, from, next) {
			//清除本地localStorage缓存
			if(from.name == null){
				localStorage.removeItem('currentLabel');
				localStorage.removeItem('type');
				localStorage.removeItem('inputValue');
				localStorage.removeItem('currentLabelPublic');
			}
			next()
		},
		methods: {
			datetime(){

			},
			showPopup() {
				this.showaa = !this.showaa;
				// console.log(this.showaa)
			},
			// 提交预约接口
      updataHousePrice() {
			  if(Number(this.offerPrice) < Number(this.floorPrice)){
          responseUtil.alertErrMsg(that,"报价不允许低于成本价！")
			    return
        }
				let that = this
				let initData = {};
				initData.user_id = getStaffId(); //用户id
				initData.roomHouse_id = this.roomId
				initData.price = this.offerPrice
				initData.isChangePrice = this.isChangePrice
				initData.type = '1'  // 报价修改
        updataHousePrice(initData).then(function(response) {
					responseUtil.dealResponse(that, response, () => {
						if (response.data.code==0){
							responseUtil.alertMsg(that,response.data.msg)
							setTimeout(()=>{
								that.leftReturn();   //交互返回
							},2000)

						}else {
							responseUtil.alertMsg(that,response.data.msg)
						}

					})
				})
			},
			leftReturn() {
        this.$router.go(-1)
			},
			// formatDate(date) {
			// 	console.log(date.getMinutes())
			// 	if ((date.getMonth() + 1) < 10) {
			// 		if ((date.getMinutes() ) < 10) {
			// 			return `${date.getFullYear()}/0${date.getMonth() + 1}/${date.getDate()} ${date.getHours()}:0${date.getMinutes()}`;
			// 		}
			// 		return `${date.getFullYear()}/0${date.getMonth() + 1}/${date.getDate()} ${date.getHours()}:${date.getMinutes()}`;
			// 	}
			// 	if ((date.getMinutes() ) < 10) {
			// 		return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()} ${date.getHours()}:0${date.getMinutes()}`;
			// 	}else{
			// 		return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()} ${date.getHours()}:${date.getMinutes()}`;
			// 	}
			//
			// },
			formatDate(dateTime) {
				// console.log(dateTime.getMinutes())
				let year = `${dateTime.getFullYear()}`;
				let month  = `${dateTime.getMonth() + 1}` ;
				let date = `${dateTime.getDate()}` ;
				let hours = `${dateTime.getHours()}` ; ;
				let minutes = `${dateTime.getMinutes()}` ; ;
				if(month < 10){
					month = `0${dateTime.getMonth() + 1}`
				}
				if(date < 10){
					date = `0${dateTime.getDate()}`
				}
				if(hours < 10){
					hours = `0${dateTime.getHours()}`
				}
				if( minutes < 10){
					minutes = `0${dateTime.getMinutes()}`
				}
				return year+ '/' + month+ '/' + date + ' ' + hours + ':' + minutes;

			},
			onConfirm(date) {
				// console.log(date)
				this.show = false;
				this.dateformat = this.formatDate(date);
				this.showaa = false;
				if (!date){
					this.dateTime=true
				}else{
					this.dateTime=false
				}
			},
			onCancel(){
				this.showaa = false;
			},
			initData() {
				let that = this
				let initData = {}
				initData.room_id = that.roomId;
				initData.user_id = globaluserId();
				roomDetail(initData).then(function(response) {
					responseUtil.dealResponse(that, response, () => {

						that.appointmentData = response.data.data
						that.appointmentData.photo = response.data.data.roomPhotoList.length > 0 ? response.data.data.roomPhotoList[0].roomPhotoPath : that.errorImg
						that.appointmentData.photo = response.data.data.roomPhotoList.length > 0 ? response.data.data.roomPhotoList[0].roomPhotoPath : that.errorImg
						that.offerPrice = response.data.data.roomRent  //报价
						that.floorPrice = response.data.data.floorPrice  //报价
						that.isChangePrice='1'
					})
				})
				// console.log(this.show)
			},
			chooseTime() {
				this.show = !this.show
			},
			save() {

			}
		}
	}
</script>

<style scoped>
	/*保存按钮不可点击样式*/
	.saveButton_Disable{
		/*background: linear-gradient(to right, rgba(184, 184, 184, 0.2), #B8B8B8 20%) repeat scroll 0% 0%;*/
		background-color: rgba(184, 184, 184, 0.2);
		width: 345px;
		height: 45px;
		border-radius: 8px;
		margin:120px 15px 15px 15px;
		font-size: 18px;
		color: white;
		line-height: 1.22rem;
		text-align: center;
	}
	.saveButton_Enable{
		background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;
		color: white;
		width: 345px;
		height: 45px;
		border-radius: 8px;
		margin:240px 15px 15px 15px;
		font-size: 18px;
		line-height: 1.22rem;
		text-align: center;
	}
	.content-divide {
		font-size: 12px;
		color: #efefef;
		margin-left:10px;
		margin-right:5px;
	}
	.content-none {
		padding: 3px;
		margin: 0 15px;
		border-radius: 50%;
		background-color: #c7c7c7;
	}
  .content-have {
    padding: 3px;
    margin: 0 10px;
    /*border-radius: 50%;*/
    /*background-color: #ff5d3b;*/
  }

  .content-none {
    padding: 3px;
    margin: 0 10px;
    border-radius: 50%;
    background-color: #c7c7c7;
  }
	.roomMesage{
		display: flex;
		flex-direction: column;
		justify-content: left;
		padding: 5px;
	}
	.areaDiv{
		display: flex;
		align-items: center;
		margin-top: 7px;
		font-size: 12px;

	}
	.area {
		color: rgba(102, 102, 102, 1);
		font-size: 12px;
		text-align: left;
		font-family: PingFangSC-Regular;
	}

	.devide {
		color: rgba(102, 102, 102, 1);
		font-size: 12px;
		margin: 0px 7px;
		font-family: PingFangSC-Regular;

	}

	.roomPhoto {
		width: 151.2px;
		height: 100.08px;
		/*padding: 5px;*/
		margin: 5px;
		border-radius: 8px;
		background-repeat: no-repeat;
		background-size: cover;
	}
	.roomPhotoImage{
		border-radius: 8px;
		width: 100%;
		height: 100%;
	}
	.submitAppointment {
		width: 345px;
		height: 45px;
		border-radius: 8px;
		margin: 210px 15px 20px 15px;
		background: linear-gradient(to right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
		font-size: 16px;
		text-align: center;
		font-family: PingFangSC-Semibold;
		color: white;
		line-height: 45px;
	}

	.inputDate {
		width: 190px;
		height: 20px;
		/*margin-left: 110px;*/
		outline-style: none;
		border: 0px;
		font-size: 15px;
		text-align: left;
		font-family: PingFangSC-Regular;
		color: rgba(255, 93, 59, 1);
	}

	.devide2 {
		width: 1px;
		height: 14px;
		color: rgba(239, 239, 239, 1);
	}

	.timeText {
		height: 21px;
		color: rgba(34, 34, 34, 1);
		font-size: 15px;
		text-align: left;
		font-family: PingFangSC-Semibold;
	}




	.timePick {
		width: 18.56px;
		height: 19.24px;
		/*margin-left: 15px;*/
	}

  .part-inputpart-row-header{
    font-size: 15px;
    font-weight: 900;
  }
  .part-inputpart-row-right {
    margin-right: 20px;
    font-size: 15px;
    font-weight: 900;
  }
  .part-inputpart-row-short-graytext{
    color: #d8d8d8;
  }

  .part-inputpart-row {
    display: flex;
    flex-direction: row;
    border-radius: 8px;
    align-items: center;
    height: 45px;
    background-color: #ffffff;
    margin-top: 15px;
  }

	.timeDiv {
		width: 92%;
		height: 50px;
		margin: 0 auto;
		border-radius: 8px;
		background-color: rgba(255, 255, 255, 1);
		margin-top: 15px;
		display: flex;
		align-items: center;
	}

	.spanMonth {
		color: rgba(255, 93, 59, 1);
		font-size: 10px;
		text-align: left;
		font-family: PingFangSC-Regular;
	}

	.money {
		width: 70px;
		height: 24px;
		margin-top: 7px;
		color: rgba(255, 93, 59, 1);
		font-size: 16px;
		font-family: Oswald-Regular;

	}

	.address {
		color: rgba(34, 34, 34, 1);
		font-size: 15px;
		font-family: PingFangSC-Regular;

	}
	.img_group {
		height: 75px;
		margin: 29px 0px;
	}

	.setPsd {
		width: 112px;
		height: 40px;
		color: rgba(34, 34, 34, 1);
		font-size: 28px;
		text-align: left;
		font-family: PingFangSC-Semibold;
		position: relative;
		font-weight: bold;
		left: 24px;
		bottom: 40px;
	}

	.oneImg {
		width: 58px;
		height: 66px;
	}

	.appointmentDetail {
		width: 92%;
		margin: 0 auto;
		height: auto;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		border-radius: 8px;
		background-color: rgba(255, 255, 255, 1);

	}
	.selectTime{
		width: 10rem;
		height: 3rem;
	}
  .content-divide {
    font-size: 12px;
    color: #efefef;
    margin: 0 10px;
  }
  .nopadding{
    padding: 0;
    flex: auto;
  }
</style>
